import React, {Component} from 'react';
import {Forms} from '../components/forms';
import {PageContext} from '../components/page_context';
import {UTILS} from '../../diplomacy/utils/utils';
import {Helmet} from 'react-helmet';
import {Navigation} from '../components/navigation';

class User extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: null,
    };
  }


  onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const password = e.currentTarget.password.value;
    let errors = null;
    if (password.length === 0) {
      errors = 'Field is mandatory';
      this.setState({errors});
      return;
    }

    if (password.length < 6) {
      errors = 'This should be at least 6 characters long';
      this.setState({errors});
      return;
    }
    try {
      const valid = await this.context.connection.updateUserPassword(password, UTILS.cookie.getCookie('token'));
      if (valid) {
        form.reset();
        this.context.success('Password has been updated');
      }
    }
    catch(error) {
      errors = error.message;
    }

    this.setState({errors});
  };

  render() {
    const {state: {errors}, context: page} = this;
    const navigation = [
      ['Load a game from disk', page.loadGameFromDisk],
      ['Logout', page.logout],
      [`${UTILS.html.UNICODE_SMALL_LEFT_ARROW} Games`, () => page.loadGames()],
    ];

    if (page.state.isAdmin) {
      navigation.push(['Users management', page.usersManagement]);
    }
    return (
      <main>
        <Helmet>
          <title>Profile | Diplomacy</title>
        </Helmet>
        <Navigation title={'Profile'}
                    username={page.channel.username} navigation={navigation}/>
        <form onSubmit={this.onSubmit}>
          {Forms.createRow(
            Forms.createColLabel('password', 'Password:'),
            <>
              <input className={`form-control${errors ? ' danger text-danger border-danger' : ''}`}  id={'password'} type={'password'} />
              {errors ? <span className="danger text-danger">{errors}</span> : ''}
            </>
          )}

          {Forms.createRow(Forms.createColLabel('', ''), Forms.createSubmit('Update password', true, () => {}))}
        </form>
      </main>
    );
  }
}

User.contextType = PageContext;
export default User;
