// ==============================================================================
// Copyright (C) 2019 - Philip Paquette, Steven Bocco
//
//  This program is free software: you can redistribute it and/or modify it under
//  the terms of the GNU Affero General Public License as published by the Free
//  Software Foundation, either version 3 of the License, or (at your option) any
//  later version.
//
//  This program is distributed in the hope that it will be useful, but WITHOUT
//  ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
//  FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more
//  details.
//
//  You should have received a copy of the GNU Affero General Public License along
//  with this program.  If not, see <https://www.gnu.org/licenses/>.
// ==============================================================================

import React from 'react';
import {Forms} from '../components/forms';

export class UsersView {
    constructor(user, callbacks, errors) {
        this.user = user;
        this.callbacks = callbacks;
        this.errors = errors;
    }


    get = (fieldName) => {
        const {callbacks, user: {username, is_admin}} = this
        const {errors: {[username]: error}} = this
        if (fieldName === 'isAdmin') {
            return is_admin ? 'Yes' : 'No';
        }
        if (fieldName === 'password') {
            return <form onSubmit={callbacks('password', username)}>
                <input className={`form-control${error ? ' danger text-danger border-danger' : ''}`} name={'password'} id={`password-${username}`} type={'password'} style={{display: 'inline-block', marginRight: 10, width: '80%'}} />
                {Forms.createSubmit('Update', false, () => {}, `submit-password-${username}`)}
                {error ? <span className="danger text-danger" style={{display: 'block'}}>{error}</span> : ''}
            </form>
        }
        if (fieldName === 'actions') {
            return <>
                {Forms.createSubmit('Toggle administration', false, callbacks('toggle', username), `toggle-admin-${username}`)}
                <span style={{marginInline: 10}}> / </span>
                {Forms.createSubmit('Delete', false, callbacks('delete', username), `delete-${username}`)}
            </>
        }
        return this.user[fieldName];
    }
}
