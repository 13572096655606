// ==============================================================================
// Copyright (C) 2019 - Philip Paquette, Steven Bocco
//
//  This program is free software: you can redistribute it and/or modify it under
//  the terms of the GNU Affero General Public License as published by the Free
//  Software Foundation, either version 3 of the License, or (at your option) any
//  later version.
//
//  This program is distributed in the hope that it will be useful, but WITHOUT
//  ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
//  FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more
//  details.
//
//  You should have received a copy of the GNU Affero General Public License along
//  with this program.  If not, see <https://www.gnu.org/licenses/>.
// ==============================================================================
import React from "react";
import {FancyBox} from "../../components/fancyBox";
import PropTypes from "prop-types";
import {UTILS} from "../../../diplomacy/utils/utils";
import Octicon, {ArrowLeft} from "@primer/octicons-react";

const DIPLOMATIC_ORDER_PHASE_TIME = [
    [0, '(no deadline)'],
    [60, '01 minute'],
    [60 * 5, '05 minutes'],
    [60 * 10, '10 minutes'],
    [60 * 15, '15 minutes'],
    [60 * 30, '30 minutes'],
    [60 * 60, '01 hour'],
    [60 * 60 * 2, '02 hours'],
    [60 * 60 * 24, '1 day'],
    [60 * 60 * 24 * 3, '3 days'],
    [60 * 60 * 24 * 7, '1 week'],
];
const RETREAT_PHASE_TIME = [
    [0, '(no deadline)'],
    [60, '01 minute'],
    [60 * 5, '05 minutes'],
    [60 * 10, '10 minutes'],
    [60 * 15, '15 minutes'],
    [60 * 60 * 24, '1 day'],
    [60 * 60 * 24 * 3, '3 days'],
    [60 * 60 * 24 * 7, '1 week'],
];
const ARMY_PHASE_TIME = [
    [0, '(no deadline)'],
    [60, '01 minute'],
    [60 * 5, '05 minutes'],
    [60 * 10, '10 minutes'],
    [60 * 15, '15 minutes'],
    [60 * 60 * 24, '1 day'],
    [60 * 60 * 24 * 3, '3 days'],
    [60 * 60 * 24 * 7, '1 week'],
];

export class PanelChooseSettings extends React.Component {
    constructor(props) {
        super(props);
        this.onCheckNoPress = this.onCheckNoPress.bind(this);
        this.onSelectTimeSelection = this.onSelectTimeSelection.bind(this);
        this.onSetRegistrationPassword = this.onSetRegistrationPassword.bind(this);
        this.onSetGameID = this.onSetGameID.bind(this);
    }

    onCheckNoPress(event) {
        this.props.onUpdateParams({no_press: event.target.checked});
    }

    onSelectTimeSelection(name) {
        return (event) => {
            this.props.onUpdateParams({
                phase_times: {
                    ...this.props.params.phase_times,
                    [name]: parseInt(event.target.value),
                },
            });
        }
    }

    onSetRegistrationPassword(event) {
        this.props.onUpdateParams({registration_password: event.target.value});
    }

    onSetGameID(event) {
        let gameID = event.target.value;
        if (!gameID)
            gameID = UTILS.createGameID(this.props.username);
        this.props.onUpdateParams({game_id: gameID});
    }

    render() {
        return (
            <FancyBox title={'Other settings'} onClose={this.props.cancel}>
                <div>
                    <form>
                        <div className="form-group row align-items-center mb-2">
                            <label className="col-md col-form-label" htmlFor="deadline">Diplomatic and Movement phase time</label>
                            <div className="col-md">
                                <select id="deadline" className="custom-select custom-select-sm"
                                        value={this.props.params.phase_times.diplomatic_order}
                                        onChange={this.onSelectTimeSelection('diplomatic_order')}>
                                    {DIPLOMATIC_ORDER_PHASE_TIME.map((deadline, index) => (
                                      <option key={index} value={deadline[0]}>{deadline[1]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row align-items-center mb-2">
                            <label className="col-md col-form-label" htmlFor="deadline">Retreat phase time</label>
                            <div className="col-md">
                                <select id="deadline" className="custom-select custom-select-sm"
                                        value={this.props.params.phase_times.retreat}
                                        onChange={this.onSelectTimeSelection('retreat')}>
                                    {RETREAT_PHASE_TIME.map((deadline, index) => (
                                      <option key={index} value={deadline[0]}>{deadline[1]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row align-items-center mb-2">
                            <label className="col-md col-form-label" htmlFor="deadline">Army build</label>
                            <div className="col-md">
                                <select id="deadline" className="custom-select custom-select-sm"
                                        value={this.props.params.phase_times.army}
                                        onChange={this.onSelectTimeSelection('army')}>
                                    {ARMY_PHASE_TIME.map((deadline, index) => (
                                      <option key={index} value={deadline[0]}>{deadline[1]}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-md col-form-label" htmlFor="registration-password">Login
                                password</label>
                            <div className="col-md">
                                <input type="password" className="form-control form-control-sm"
                                       id="registration-password"
                                       value={this.props.params.registration_password}
                                       onChange={this.onSetRegistrationPassword} placeholder="(no password)"/>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-md col-form-label" htmlFor="game-id">Game ID</label>
                            <div className="col-md">
                                <input type="text" className="form-control form-control-sm"
                                       id="game-id"
                                       value={this.props.params.game_id}
                                       onChange={this.onSetGameID}/>
                            </div>
                        </div>
                        <div className="custom-control custom-checkbox mb-5">
                            <input type="checkbox" className="custom-control-input" id="no-press"
                                   checked={this.props.params.no_press} onChange={this.onCheckNoPress}/>
                            <label className="custom-control-label" htmlFor="no-press">No messages allowed</label>
                        </div>
                    </form>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <button type="button" className="btn btn-secondary btn-sm btn-block"
                                onClick={() => this.props.backward()}>
                            <Octicon icon={ArrowLeft}/>
                        </button>
                    </div>
                    <div className="col-sm">
                        <button type="button" className="btn btn-success btn-sm btn-block inline"
                                onClick={() => this.props.forward()}>
                            <strong>create the game</strong>
                        </button>
                    </div>
                </div>
            </FancyBox>
        );
    }
}

PanelChooseSettings.propTypes = {
    backward: PropTypes.func.isRequired,
    forward: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    onUpdateParams: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired
};
