// ==============================================================================
// Copyright (C) 2019 - Philip Paquette, Steven Bocco
//
//  This program is free software: you can redistribute it and/or modify it under
//  the terms of the GNU Affero General Public License as published by the Free
//  Software Foundation, either version 3 of the License, or (at your option) any
//  later version.
//
//  This program is distributed in the hope that it will be useful, but WITHOUT
//  ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
//  FOR A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more
//  details.
//
//  You should have received a copy of the GNU Affero General Public License along
//  with this program.  If not, see <https://www.gnu.org/licenses/>.
// ==============================================================================
import {Future} from "./future";

/** Class FutureEvent (like Python's Tornado FutureEvent). **/
export class FutureEvent {
    constructor() {
        this.__future = new Future();
    }

    set(error) {
        if (!this.__future.done())
            if (error)
                this.__future.setException(error);
            else
                this.__future.setResult(null);
    }

    clear() {
        if (this.__future.done())
            this.__future = new Future();
    }

    wait() {
        return this.__future.promise();
    }

    isWaiting() {
        return !this.__future.done();
    }
}
