import React, {Component} from 'react';
import {Forms} from '../components/forms';
import {PageContext} from '../components/page_context';
import {UTILS} from '../../diplomacy/utils/utils';
import {Table} from '../components/table';
import {UsersView} from '../utils/users_view';
import {Helmet} from 'react-helmet';
import {Navigation} from '../components/navigation';


const TABLE_LOCAL_USERS = {
  username: ['Username', 0],
  isAdmin: ['is Admin', 1],
  password: ['Update password', 2],
  actions: ['Actions', 3],
};
class Users extends Component {

  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      users: [],
      actions: {
        errors: {},
      },
    };
  }

  callbacks = (name, username) =>  {
    switch (name) {
      case 'password':
        return async (e) => {
          e.preventDefault();
          const form = e.currentTarget;
          const password = e.currentTarget.password.value;
          let {state: {actions: {errors}}} = this;
          delete errors[username];
          if (password.length === 0) {
            errors[username] = 'Field is mandatory';
            this.setState({actions: {errors: errors}});
            return;
          }

          if (password.length < 6) {
            errors[username] = 'This should be at least 6 characters long';
            this.setState({actions: {errors: errors}});
            return;
          }
          try {
            const valid = await this.context.connection.updateUserPassword(password, UTILS.cookie.getCookie('token'), username);
            if (valid) {
              form.reset();
              this.context.success('Password has been updated');
            }
          }
          catch(error) {
            errors[username] = error.message;
          }

          this.setState({actions: {errors}});
        };

      case 'toggle':
        return async (e) => {
          e.preventDefault();
          try {
            const valid = await this.context.connection.toggleAdmin(username, UTILS.cookie.getCookie('token'));
            if (valid) {
              this.context.success('User has been toggled');
              this.setState({users: await this.context.connection.getUsers(UTILS.cookie.getCookie('token'))})
            }
          }
          catch(error) {
            this.context.error('Error while trying to toggle the user: ' + error.message);
          }
        };

      case 'delete':
        return async (e) => {
          e.preventDefault();
          try {
            const valid = await this.context.connection.deleteUser(username, UTILS.cookie.getCookie('token'));
            if (valid) {
              this.context.success('User has been deleted');
              this.setState({users: await this.context.connection.getUsers(UTILS.cookie.getCookie('token'))})
            }
          }
          catch(error) {
            this.context.error('Error while trying to delete the user: ' + error.message);
          }
        };

      default:
        return () => {};
    }
  }

  wrapper = (data) => {
    return new UsersView(data, this.callbacks, this.state.actions.errors);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const {currentTarget: {username: {value: username}, password: {value: password}, is_admin: {checked: admin}}} = e;

    const errors = {};
    [
      {name: 'username', value: username.trim(), limit: 3, regExp: /^[a-zA-Z0-9_-]+$/, error: 'Only number, alphabetical and _ - characters are allowed'},
      {name: 'password', value: password.trim(), limit: 6},
    ].forEach(({name, value, limit, ...data}) => {
      if (value.length === 0) {
        errors[name] = 'Field is mandatory';
        return;
      }

      if (value.length < limit) {
        errors[name] = `This should be at least ${limit} characters long`;
        return;
      }

      if (data.regExp && !data.regExp.test(value)) {
        errors[name] = data.error;
      }
    })

    const {context: page} = this;
    if (!errors.username) {
      try {
        const serverUsername = await page.connection.getUser(username, UTILS.cookie.getCookie('token'));
        if (serverUsername) {
          errors.username = 'This user already exists';
        }
      }
      catch (error) {
        errors.username = error.message;
      }

    }

    const state = {errors: {...errors}};

    if (Object.keys(errors).length === 0) {
      form.reset();
      try {
        const valid = await page.connection.addUser(username, password, admin, UTILS.cookie.getCookie('token'));
        if (valid) {
          page.success(`The user ${username} has been added.`);
        }
        state.users = await page.connection.getUsers(UTILS.cookie.getCookie('token'));
      }
      catch (error) {
        page.error('Error when adding the user: ' + error.message)
      }

    }

    this.setState({...state});
  };

  async componentDidMount() {
    const {context: page} = this;
    try {
      const users = await page.connection.getUsers(UTILS.cookie.getCookie('token'));
      this.setState({users: users});
    }
    catch (error) {
      page.error('Error when trying to retrieve users: ' + error.message);
    }
  }

  render() {
    const {state: {errors}, context: page} = this;
    const navigation = [
      ['Profile', page.userProfile],
      ['Load a game from disk', page.loadGameFromDisk],
      ['Logout', page.logout],
      [`${UTILS.html.UNICODE_SMALL_LEFT_ARROW} Games`, () => page.loadGames()],
    ];
    return (
      <main>
        <Helmet>
          <title>User management | Diplomacy</title>
        </Helmet>
        <Navigation title={'User management'}
                    username={page.channel.username} navigation={navigation}/>
        <form onSubmit={this.onSubmit}>
          {Forms.createRow(
            Forms.createColLabel('username', 'Username:'),
            <>
              <input className={`form-control${errors.username ? ' danger text-danger border-danger' : ''}`} id={'username'} type={'text'} />
              {errors.username ? <span className="danger text-danger">{errors.username}</span> : ''}
            </>
          )}
          {Forms.createRow(
            Forms.createColLabel('password', 'Password:'),
            <>
              <input className={`form-control${errors.password ? ' danger text-danger border-danger' : ''}`}  id={'password'} type={'password'} />
              {errors.password ? <span className="danger text-danger">{errors.password}</span> : ''}
            </>
          )}

          {Forms.createRow(
            Forms.createColLabel('', ''),
            <div className={'form-check'}>
              {Forms.createCheckbox('is_admin', 'Is admin.')}
            </div>
          )}

          {Forms.createRow(Forms.createColLabel('', ''), Forms.createSubmit('Add user', true, () => {}))}
        </form>
        <Table className={"table table-striped"} data={this.state.users} columns={TABLE_LOCAL_USERS} caption={"Users"} wrapper={this.wrapper} />
      </main>
    );
  }
}

Users.contextType = PageContext;
export default Users;
